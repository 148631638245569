.App {}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

dl dt {
  /*noinspection CssUnresolvedCustomProperty*/
  color: var(--chakra-colors-gray-500);
  /*noinspection CssUnresolvedCustomProperty*/
  font-size: var(--chakra-fontSizes-xs);
}
dl dd {
  margin-bottom: 10px;
}

table tr:last-child td {
  border: none;
}

.sticky {
  position: sticky;
  top: 0;
}
